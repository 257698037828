import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Image from '../elements/Image';
import { CoralCheck } from '../elements/Icon';
import { ButtonArrow } from '../elements/buttons/Button';
import { Logo } from '../elements/Icon';
import { InViewAnimator } from '~/components/elements/Animator';
import { Link } from 'gatsby';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '../forms/fields/InputField';
import SelectField from '../forms/fields/SelectField';
import CheckboxField from '../forms/fields/CheckboxField';

export const Contact = (page) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fields = page?.contact?.v2;

  return (
    <section className="!mt-0 py-32 md:pt-40">
      <div className="container-v2">
        <AnimatePresence exitBeforeEnter key="form-layout">
          <motion.div layout>
            {!isSubmitted ? (
              <motion.div key="contact-header" className="text-center mb-12 space-y-3">
                <h1 className="t-64" dangerouslySetInnerHTML={{ __html: fields.heading }} />
                <div className="t-20" dangerouslySetInnerHTML={{ __html: fields.content }} />
              </motion.div>
            ) : null}

            <motion.div
              key="contact-form-container"
              className="rounded-[10px] bg-white p-8 md:p-12 max-w-[588px] mx-auto w-full"
              style={{
                border: '1px solid rgba(6, 42, 47, 0.08)',
                boxShadow: '0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)',
              }}
            >
              {!isSubmitted ? (
                <motion.div key="contact-form">
                  <ContactForm setIsSubmitted={setIsSubmitted} submitButtonText={'Submit message'} />
                </motion.div>
              ) : (
                <motion.div
                  className="text-center space-y-4 py-4"
                  key="contact-form-confirmation"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.5 } }}
                  exit={{ opacity: 0 }}
                >
                  <h2 className="t-40">Thanks for your message.</h2>
                  <div className="t-20">Someone will be in touch shortly.</div>
                </motion.div>
              )}
            </motion.div>

            <div className="pt-[85px] md:pt-[112px] text-center">
              <div className="grid md:grid-cols-3 gap-4">
                {fields.offices.map((office) => (
                  <div key={office.heading} className="py-[40px] px-[32px] bg-white rounded-[16px] space-y-2">
                    <div className="t-24 font-medium" dangerouslySetInnerHTML={{ __html: office.heading }} />
                    <div className="t-18 text-dark70" dangerouslySetInnerHTML={{ __html: office.content }} />
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

const ContactForm = ({ setIsSubmitted, submitButtonText }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = Yup.object({
    full_name: Yup.string().required('Full name is required'),
    email: Yup.string().email('Invalid email address').required('Email address is required'),
    message: Yup.string().required('Your message is required'),
    accept_terms: Yup.bool().oneOf([true], 'Field must be checked'),
  });

  return (
    <Formik
      initialValues={{
        full_name: '',
        email: '',
        message: '',
        accept_terms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const formData = new FormData();

        for (var key in values) {
          formData.append(key, values[key]);
        }

        console.log('formData', formData);

        fetch('https://getform.io/f/e13e1065-b0ff-4e8d-a608-a179be7af57d', {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitted(true);
              setErrorMessage(''); // reset error message
              resetForm();
            }
          })
          .catch((error) => {
            setErrorMessage(error);
          });
      }}
    >
      {(props) => (
        <Form className="w-full grid grid-cols-1 sm:grid-cols-2 gap-[16px] sm:gap-[20px]">
          <fieldset className="">
            <InputField name="full_name" label="Full Name" />
          </fieldset>
          <fieldset className="">
            <InputField name="email" label="Email Address" type="email" />
          </fieldset>
          <fieldset className="sm:col-span-2">
            <InputField name="message" type="textarea" label="Message" />
          </fieldset>

          <fieldset className="sm:col-span-2">
            <CheckboxField
              name="accept_terms"
              className="text-[#071B1EB2]"
              label={
                <div className="">
                  I agree with{' '}
                  <Link className="font-semibold hover:underline hover:text-orange" to={`/saas-terms-and-conditions/`}>
                    Setpoint's Terms of Service{' '}
                  </Link>{' '}
                  and{' '}
                  <Link className="font-semibold hover:underline hover:text-orange" to={`/privacy-policy/`}>
                    Privacy Policy.
                  </Link>
                </div>
              }
            />
          </fieldset>

          <div className="pt-4 sm:col-span-2">
            <button className="btn btn-orange w-full text-center" type="submit">
              {submitButtonText || 'Submit'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
