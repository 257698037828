import { useField } from 'formik';
import React from 'react';

export default function InputField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const labelClassName = props.labelClassName;

  return (
    <label className={`block w-full text-darkGreen  ${props.className || ''}`}>
      <div className="mb-1">
        <div className={`${labelClassName}  t-16 `}>{label}</div>
      </div>
      <div className="relative w-full">
        {props.type === 'textarea' ? (
          <textarea
            placeholder={props.placeholderText}
            className={`min-h-[131px] w-full px-4 py-3 rounded-[10px] flex-1 leading-4 bg-white border-[rgba(7,27,30,0.30)] border transition-colors duration-200 ${
              isInvalid ? '!border-red ' : 'focus:!border-midGreen'
            }`}
            {...field}
            {...other}
          />
        ) : (
          <input
            placeholder={props.placeholderText}
            className={`w-full px-4 py-3 rounded-[10px] flex-1 leading-4 bg-white border-[rgba(7,27,30,0.30)] border transition-all duration-200 ${
              isInvalid ? '!border-red ' : 'focus:!border-midGreen'
            }`}
            {...field}
            {...other}
          />
        )}
      </div>
      {isInvalid && <div className="text-red text-[14px] pt-1">{meta.error}</div>}
    </label>
  );
}
