import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

import { useResources } from '../../hooks/useResources';
import DateFormat from '~/components/elements/date';
import { Button } from '../elements/buttons/Button';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { Post } from '~/components/flexible/LatestNews';
import { globalHistory } from '@reach/router';
import { VideoIcon } from '../elements/Icon';

export const Resources = (page) => {
  const layout = page?.resources?.layout;
  if (!layout || layout === 'v1') {
    return <V1 page={page} />;
  } else {
    return <V2 page={page} />;
  }
};

function HeaderBar({
  heading,
  link,
  filterBy,
}: {
  heading: string;
  link?: { url: string; title: string };
  filterBy?: (str) => void;
}) {
  return (
    <div className="flex flex-col md:flex-row flex-wrap items-center justify-center text-center md:text-left md:justify-between -ml-6 mb-6 sm:mb-8">
      <h2 className="t-36 pl-6 pb-3" dangerouslySetInnerHTML={{ __html: heading }} />
      {link?.url?.length > 0 ? (
        <div className="pl-6 pb-3" onClick={filterBy}>
          <Button type="link" link={link} />
        </div>
      ) : null}
    </div>
  );
}

function TwoColumnThreePosts({ posts = [] }) {
  return (
    <div className="grid md:grid-cols-2 gap-x-16 lg:gap-x-28 gap-y-12">
      {posts?.length > 0 ? (
        <div>
          <Post post={posts[0]} />
        </div>
      ) : null}

      <div>
        <div className="space-y-10">
          {posts[1] ? <Post type="small" post={posts[1]} /> : null}
          {posts[2] ? <Post type="small" post={posts[2]} /> : null}
        </div>
      </div>
    </div>
  );
}

function V2({ page }) {
  let resources = useResources();
  const hideCustomerStories = page?.resources?.hide_customer_stories;
  const allResources = resources;

  const [activeCat, setActiveCat] = useState('All');
  const [search, setSearch] = useState('');
  let filterItems = ['All'];
  const [filteredResources, setFilteredResources] = useState(resources);
  const [featuredResource, setFeaturedResource] = useState(null);

  resources.sort((a, b) => b.date.localeCompare(a.date));

  function setActiveCatByHash() {
    if (window.location.hash) {
      const hash = window.location.hash.replace('#', '');
      if (hash.length > 0) {
        setActiveCat(decodeURIComponent(hash).replace('-', ' '));
        if (window.scrollY > 100) window.scrollTo(0, 0);
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setActiveCatByHash();
    }

    // Subscribe to hash changes
    const unsubscribe = globalHistory.listen(setActiveCatByHash);

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (activeCat && activeCat !== 'All' && typeof window !== 'undefined') {
      window.location.hash = encodeURIComponent(activeCat.replace(' ', '-'));
    }
  }, [activeCat]);

  useEffect(() => {
    if (resources) {
      setFilteredResources(resources);
      setFeaturedResource(resources.filter((r) => r.isSticky)[0]);
    }
  }, [resources]);

  useEffect(() => {
    let temp = [];

    if (activeCat === 'All') {
      temp = resources;
    } else {
      temp = resources.filter((listItem) => {
        let match = false;
        listItem?.categories?.nodes.map((name) => {
          if (name.name == activeCat) match = true;
        });
        return match;
      });
    }

    if (search.length > 0) {
      const fuse = new Fuse(temp, {
        keys: [
          'title',
          'categories.nodes.name',
          'author.node.authorDisplayInformation.displayDetails.name',
          'author.node.authorDisplayInformation.displayDetails.role',
          'postTypePost.summary.summary',
        ],
      });
      temp = fuse.search(search).map(({ item }) => item);
    }

    setFilteredResources(temp);
  }, [activeCat, search]);

  //Map through categories in use and add them as filterItems
  resources?.map(({ categories }) => {
    categories?.nodes.map(({ name }) => {
      if (!filterItems.includes(name)) filterItems.push(name);
    });
  });

  // Order the filter items
  const ordered = ['All', 'Blog', 'Press Releases', 'Customer Stories'];
  const order = new Map(ordered.map((value, i) => [value, i]));
  filterItems = filterItems.sort((a, b) => order.get(a) - order.get(b));

  let pressPosts = allResources.filter(
    ({ categories }) => categories.nodes.filter(({ name }) => name === 'Press Releases').length > 0
  );
  let blogPosts = allResources.filter(
    ({ categories }) => categories.nodes.filter(({ name }) => name === 'Blog').length > 0
  );
  let customerStoriesPosts = allResources.filter(
    ({ categories }) => categories.nodes.filter(({ name }) => name === 'Customer Stories').length > 0
  );

  return (
    <div className="pt-[80px] ">
      {/* Filters */}
      <section className="pt-[30px] pb-[60px] md:py-[72px] container !max-w-[1200px]">
        <div className="w-full flex flex-col sm:flex-row flex-wrap justify-between items-center">
          <ul className="flex-auto sm:mr-8 mb-6 sm:mb-0">
            {filterItems?.map((name) => (
              <li
                key={name}
                onClick={() => setActiveCat(name)}
                className="inline-block my-2 mr-2 t-16 lg:mr-[12px] text-[#071B1EB2]"
              >
                <span
                  className={`${
                    name === activeCat
                      ? 'bg-darkestGreen text-taupe border-darkestGreen'
                      : 'transition cursor-pointer border-[rgba(7,_27,_30,_0.16)] hover:bg-taupe'
                  }  py-[9px] px-[18px]  rounded-[24px] border select-none`}
                  dangerouslySetInnerHTML={{ __html: name }}
                />
              </li>
            ))}
          </ul>

          <div className="">
            <form action="#" className="relative w-[282px]">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                onKeyUp={(e) => setSearch(e.target.value)}
                className="w-full p-[7px_18px_7px_42px] rounded-[24px] t-16"
                style={{ border: '1px solid rgba(7, 27, 30, 0.16)' }}
              />
              <div className="absolute inset-y-0 left-[18px] flex flex-col items-center justify-center bottom-[2px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <circle cx="8" cy="8" r="5" stroke="#071B1E" stroke-width="1.5" />
                  <path d="M12 12L14 14" stroke="#071B1E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>
            </form>
          </div>
        </div>
      </section>

      {activeCat === 'All' && search === '' ? (
        <div>
          {/* Featured */}
          <div className="container !max-w-[1200px]">
            {featuredResource ? (
              <>
                <div className="hidden sm:block">
                  <FeaturedResource item={featuredResource} />
                </div>
                <div className="sm:hidden">
                  <Post post={featuredResource} />
                </div>
              </>
            ) : null}
          </div>

          {/* Press */}
          <section className="py-[50px] sm:py-[60px] md:py-[120px] container !max-w-[1200px]">
            <HeaderBar
              heading={'Press Releases'}
              filterBy={(e) => setActiveCat('Press Releases')}
              link={{ url: '#', title: 'View all' }}
            />
            <TwoColumnThreePosts posts={pressPosts.slice(0, 3)} />
          </section>

          {/* Blog */}
          <section className="bg-taupe py-[50px] sm:py-[60px] md:py-[120px]">
            <div className="container !max-w-[1200px]">
              <HeaderBar
                heading={'Blog'}
                filterBy={(e) => setActiveCat('Blog')}
                link={{ url: '#', title: 'View all' }}
              />
              <div className="grid md:grid-cols-3 gap-x-6 gap-y-8">
                {blogPosts.slice(0, 3).map((item, i) => (
                  <Post key={`post${item.id}`} post={item} />
                ))}
              </div>
            </div>
          </section>

          {/* Customer Stories */}
          {!hideCustomerStories && customerStoriesPosts.length > 0 ? (
            <section className=" py-[50px] sm:py-[60px] md:py-[120px]">
              <div className="container !max-w-[1200px]">
                <HeaderBar
                  heading={'Customer Stories'}
                  filterBy={(e) => setActiveCat('Customer Stories')}
                  link={{ url: '#', title: 'View all' }}
                />
                <div className="grid md:grid-cols-2 gap-8">
                  {customerStoriesPosts.slice(0, 4).map((item, i) => (
                    <Post key={`post${item.id}`} type="small" post={item} />
                  ))}
                </div>
              </div>
            </section>
          ) : null}
        </div>
      ) : (
        <div>
          <section className="pb-[50px] sm:pb-[60px] md:pb-[120px]">
            <div className="container !max-w-[1200px]">
              <HeaderBar heading={search?.length > 0 ? `Results for "${search}"` : activeCat} />
              {filteredResources?.length > 0 ? (
                <div className="grid md:grid-cols-3 gap-x-6 gap-y-8">
                  {filteredResources.map((item, i) => (
                    <Post key={`post${item.id}`} post={item} />
                  ))}
                </div>
              ) : (
                <div>
                  No results found for "{search}" —{' '}
                  <span onClick={() => setSearch('')} className="underline">
                    Clear search
                  </span>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export const FeaturedResource = ({ item }) => {
  const authorFields = item.author.node.authorDisplayInformation.displayDetails;
  const authorDisplay = authorFields.name + (authorFields.role ? ', ' + authorFields.role : '');
  return (
    <Link
      to={item?.postTypePost?.summary?.externalLink ? item?.postTypePost?.summary?.externalLink : item?.uri}
      target={item?.postTypePost?.summary?.externalLink ? '_blank' : '#'}
      className="grid md:grid-cols-2 gap-x-12 gap-y-8 items-center"
    >
      <div className="">
        <div className="aspect-[588/420] rounded-[10px] bg-darkGreen overflow-hidden">
          <Image
            image={item?.postTypePost?.summary?.image}
            objectFit={'cover'}
            className={`w-full h-full object-cover object-center`}
          />
        </div>
      </div>

      <div className="">
        <div className="w-full md:max-w-[430px] mx-auto space-y-4 lg:space-y-5 mb-2">
          <div className="t-16 space-x-5 leading-[1.25]">
            {item?.categories?.nodes.map((item, i) => (
              <span className="text-dark70" key={i} dangerouslySetInnerHTML={{ __html: item?.name }} />
            ))}
          </div>

          <h1 className="t-36 font-body text-dark leading-[1.1]" dangerouslySetInnerHTML={{ __html: item?.title }} />

          <div className="text-dark70 flex flex-col sm:flex-row leading-[1.25] sm:items-center">
            <DateFormat dateString={item?.date} />
            {authorDisplay?.length > 0 ? (
              <>
                <div className="px-[12px] text-[#cbcece] hidden sm:block">•</div>
                <div dangerouslySetInnerHTML={{ __html: '' + authorDisplay }} />
              </>
            ) : null}
          </div>
          <div className="inline-block t-16 text-dark transition-all duration-200 relative font-medium">
            Read Story
            <div className="absolute inset-x-0 bottom-[-2px] h-[2px] w-full bg-orange"></div>
          </div>
        </div>
      </div>
    </Link>
  );
};

function V1({ page }) {
  const heading = page?.resources?.heading;
  let resources = useResources();

  const [activeCat, setActiveCat] = useState('All');
  const filterItems = ['All'];

  //Map through categories in use and add them as filterItems
  resources?.map(({ categories }) => {
    categories?.nodes.map(({ name }) => {
      if (!filterItems.includes(name)) filterItems.push(name);
    });
  });

  if (activeCat === 'All') {
    resources = resources?.filter((listItem) => {
      let match = true;
      return match;
    });
  } else {
    resources = resources.filter((listItem) => {
      let match = false;
      listItem?.categories?.nodes.map((name) => {
        if (name.name == activeCat) match = true;
      });
      return match;
    });
  }

  resources.sort((a, b) => b.date.localeCompare(a.date));

  return (
    <>
      <section className="mt-20 md:mt-[140px] lg:mt-[190px]">
        {/* Page title and Filter */}
        <div className="container md:flex md:justify-between space-y-6 md:space-y-0 md:space-x-8">
          <div>
            <h1 className="t-48">{heading}</h1>
          </div>
          <ul>
            {filterItems?.map((name) => {
              const active = name === activeCat;
              return (
                <li
                  key={name}
                  onClick={() => setActiveCat(name)}
                  className="inline-block my-2 mr-2 t-14 font-medium lg:mr-4"
                >
                  <span
                    className={`${
                      active
                        ? 'bg-darkGreen text-taupe'
                        : 'bg-taupe hover:bg-darkGreen hover:text-taupe transition cursor-pointer'
                    }  py-[10px] px-4  rounded-[12px] border-[1.5px] border-darkGreen`}
                  >
                    {name}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      {/* Resource Grid */}
      {resources.slice(0, 1).map((item, i) => (
        <PrimaryResource key={i} item={item} />
      ))}
      <SplitResource resources={resources} />
    </>
  );
}

export const PrimaryResource = ({ item }) => {
  return (
    <Link
      to={item?.postTypePost?.summary?.externalLink ? item?.postTypePost?.summary?.externalLink : item?.uri}
      target={item?.postTypePost?.summary?.externalLink ? '_blank' : '#'}
      className="block section-contained bg-white py-6 md:py-[40px] mt-8 lg:mt-[50px] px-2 md:px-0 hover:opacity-[.6] transition-all duration-200"
    >
      <div className="!max-w-[1400px] container flex flex-col md:flex-row md:space-x-16 lg:space-x-20 xl:space-x-32 md:items-center justify-center md:justify-start md:!px-6 lg:!px-[40px]">
        <div className="w-full md:max-w-[588px] xl:max-w-[750px] rounded-[20px] overflow-hidden safari-overflow aspect-[7/5]">
          <Image
            image={item?.postTypePost?.summary?.image}
            objectFit={'cover'}
            className={`w-full h-full object-cover object-center`}
          />
        </div>
        <div className="w-full max-w-[465px] md:ml-0 mt-6 md:mt-0">
          {/* Masthead Content */}
          <div>
            <div className="t-18 space-x-5 md:space-x-[28px] mb-6 md:mb-[28px]">
              {item?.categories?.nodes.map((item, i) => (
                <span className="text-coral" key={i}>
                  {item?.name}
                </span>
              ))}
              <DateFormat dateString={item?.date} />
            </div>
            <h1 className="t-40 mb-2">{item?.title}</h1>
            <p
              className="t-18 max-w-[388px] mb-6 md:mb-[38px] line-clamp-3"
              dangerouslySetInnerHTML={{ __html: item?.postTypePost?.summary?.summary }}
            />

            <span className="t-16 text-coral font-bold underline hover:text-darkBlue transition-all duration-200">
              Read Story
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const SplitResource = ({ resources }) => {
  return (
    <section className="section-contained mt-[30px] space-y-[30px]">
      <div className="flex flex-col md:flex-row space-y-[30px] md:space-y-0 md:space-x-[30px]">
        {resources.slice(1, 3).map((item, i) => (
          <SplitItem article={item} key={i} width={i} />
        ))}
      </div>
      <div className="flex flex-col md:flex-row-reverse space-y-[30px] md:space-y-0 md:space-x-[30px] md:space-x-reverse">
        {resources.slice(3, 5).map((item, i) => (
          <SplitItem article={item} key={i + 0.1} width={i} />
        ))}
      </div>
    </section>
  );
};

export const SplitItem = ({ article, width }) => {
  return (
    <Link
      to={article?.postTypePost?.summary?.externalLink ? article?.postTypePost?.summary?.externalLink : article?.uri}
      target={article?.postTypePost?.summary?.externalLink ? '_blank' : '#'}
      className={`rounded-[32px] bg-white p-[23px] pb-8 md:p-6 lg:p-[40px] w-full hover:opacity-[.6] transition-all duration-200 ${
        width === 0 ? 'md:max-w-[66%] md:flex md:space-x-[30px] lg:space-x-[50px]' : 'md:max-w-[34%]'
      }`}
    >
      <div
        className={`w-full rounded-[20px] overflow-hidden safari-overflow ${
          width === 0 ? 'aspect-[10/7] md:aspect-[40/49] md:w-1/2' : 'aspect-[10/7]'
        }`}
      >
        <Image
          image={article?.postTypePost?.summary?.image}
          objectFit={`cover`}
          className={`w-full h-full object-cover object-center`}
        />
      </div>
      <div className={`${width === 0 ? 'md:w-[240px] lg:w-[360px] md:mt-auto md:mb-0' : ' '}`}>
        <div className="t-16 space-x-4 md:space-x-[20px] mb-[12px] mt-6 md:mt-[35px]">
          {article?.categories?.nodes.map((item, i) => {
            if (item?.name === 'Videos') {
              return (
                <div key={i} className="mb-2 md:mb-[20px]">
                  <VideoIcon />
                </div>
              );
            } else {
              return (
                <span className="text-coral" key={i}>
                  {item?.name}
                </span>
              );
            }
          })}
        </div>
        <h3 className="t-30 line-clamp-3 mb-6 lg:mb-[30px]">{article?.title}</h3>
        {width === 0 ? (
          <p
            className="t-18 max-w-[388px] mb-6 lg:mb-[38px]"
            dangerouslySetInnerHTML={{ __html: article?.postTypePost?.summary?.summary }}
          />
        ) : (
          <p
            className="t-18 max-w-[388px] mb-6 lg:mb-[38px] md:hidden"
            dangerouslySetInnerHTML={{ __html: article?.postTypePost?.summary?.summary }}
          />
        )}
        <span className="t-16 text-coral font-bold underline">Read Story</span>
      </div>
    </Link>
  );
};
