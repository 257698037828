import React from 'react';
import { Legal } from '~/components/flexible/Legal';
import { FourOhFour } from '~/components/flexible/FourOhFour';
import { SpeakWithUs } from '~/components/landing/SpeakWithUs';
import { Resources } from '~/components/landing/Resources';
import { Contact } from '~/components/landing/Contact';

export const LandingPage = (page) => {
  const { layout, allWpPost } = page;

  if (layout === 'fourOhFour') {
    return <FourOhFour {...page} />;
  }
  if (layout === 'staging_index') {
    return <div>staging</div>;
  } else if (layout === 'legal') {
    return <Legal {...page} />;
  } else if (layout === 'speakWithUs') {
    return <SpeakWithUs {...page} />;
  } else if (layout === 'resources') {
    return <Resources {...page} />;
  } else if (layout === 'contact') {
    return <Contact {...page} />;
  } else {
    console.log('IMPLEMENT', page);
    return null;
  }
};
