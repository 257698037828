import React, { useEffect, useState } from 'react';

export const useCleanSlug = (title) => {
  const [slug, setSlug] = useState(getSlug(title))

  function getSlug(title) {
    return title.toString().normalize('NFD').toLowerCase()
      .replace('/\s+/g', '-').replace('/&/g', '-and-')
      .replace(/[^\w-]+/g, '');
  }
  function setCleanSlug(title) {
    setSlug(getSlug(title))
  }

  return [slug, setCleanSlug]
};
