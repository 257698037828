import React from 'react';
import { useField } from 'formik';

export default function CheckboxField(props) {
  const { label, name, reverse, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const { className, labelClassName, innerClassName } = props;

  return (
    <div className={` leading-normal select-none w-full ${className} `}>
      <div onClick={() => helpers.setValue(!!!meta.value)} className="inline-flex w-full cursor-pointer group">
        <div className={`flex items-center w-full ${innerClassName} ${reverse && 'flex-row-reverse'}`}>
          <div className="relative ">
            <button
              className={`h-[13px] w-[13px] rounded-[3px] border  opacity-70 transition-opacity duration-200 group-hover:opacity-100  ${
                meta.value === true ? `bg-black !opacity-100` : isInvalid ? 'border-[#f74949]' : ''
              } `}
              type="button"
              role="checkbox"
            />
          </div>

          <div className={`pl-[15px] t-14  ${labelClassName} ${isInvalid ? '!text-red' : ''}`}>{label}</div>
        </div>
      </div>
    </div>
  );
}
