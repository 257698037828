import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/Layout';
import { Section } from '~/components/elements/Section';

import FlexibleContent from '~/components/FlexibleContent';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';

import Seo from 'gatsby-plugin-wpgraphql-seo';
import { LayoutRenderer } from '../components/LayoutRenderer';

export default function defaultPage({
  data: { wpPage, wp, allWpPost },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const pageColour = wpPage?.template?.layoutPageControls?.pageBackground;
  const layoutHide = wpPage?.template?.layoutPageControls?.hideHeaderFooter;
  const styleVersion = wpPage?.template?.layoutPageControls?.styles;

  return (
    <Layout wp={wp} pageColour={pageColour} hide={layoutHide} page={wpPage} styleVersion={styleVersion}>
      <Seo post={wpPage} />
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections_Section_Components_"
        allWpPost={allWpPost}
        sections={wpPage?.postTypePage?.sections}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
      template {
        ... on WpDefaultTemplate {
          templateName
          layoutPageControls {
            pageBackground
            hideHeaderFooter
            styles
            fieldGroupName
          }
        }
      }
    }
    wp {
      ...GeneratedWp
      header {
        optionsHeader {
          menu {
            link {
              url
              title
            }
            showSubmenu
            submenu {
              type
              withPostMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                    description
                  }
                }
              }
              withImageMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                rightColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  }
`;
