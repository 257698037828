import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LegalMasthead } from './mastheads/LegalMasthead';
import { useCleanSlug } from '~/hooks/useCleanSlug';
import { Link } from 'gatsby';
import useScrollSpy from 'react-use-scrollspy';
import { getSlug } from '../functions/getSlug';
import { ChevronRight } from '../elements/Icon';

export const Legal = (page) => {
  const [activeID, setActiveID] = useState('')
  const refs = page?.legal?.sections?.map((e) => useRef(null))
  const activeSection = useScrollSpy({
    sectionElementRefs: refs,
    offsetPx: -80,
  })

  useEffect(() => {

  }, [activeSection])

  console?.log(page)
  return (
    <>
    <section className='pt-20 md:pt-4 pb-8'>
      <LegalMasthead heading={page?.legal?.heading} updateDate={page?.legal?.updateDate}/>
    </section>
    <section className="md:py-20 container !max-w-[1200px]">
      <div className="md:flex space-y-10 md:space-y-0 md:gap-8 lg:gap-24 relative">
        <div className="md:min-w-[300px]">
          <div className="border-[1px] border-black/10 rounded-2xl p-4 lg:p-11 sticky top-[4.5rem]">
            {
              page?.legal?.sections?.map((section, i) => (
                <LegalLinks section={section} index={i} key={i} activeSection={activeSection}/>
              ))
            }
          </div>
        </div>
        <div >
          {
            page?.legal?.sections?.map((section, i) => (
              <div key={i}>
                <article ref={refs[i]} className="legal-article mb-16"
                         id={getSlug(section?.heading)}>
                  <h2 className="t-30 mb-8">{section?.heading} </h2>
                  <div className='' dangerouslySetInnerHTML={{__html: section?.content}}/>
                </article>
              </div>
            ))
          }
        </div>
      </div>
    </section>
    </>
  )
}

const LegalLinks = ({section, activeSection, index}) => {
  const [cleanSlug, setCleanSlug] = useCleanSlug(section.heading)
  const [isActive, setIsActive] = useState(false)
  useEffect(() => {
    (activeSection === index)? setIsActive(true) : setIsActive(false)
  }, [activeSection])
  return (
    <>
      <Link className={`block font-body font-bold t-16 mb-5 flex items-center justify-between ${isActive ? 'text-coral' : 'text-darkerGreen'}`}
            to={`#${cleanSlug}`}>
        {section.heading}
        <div className="w-2">
          <ChevronRight/>
        </div>
      </Link>
    </>

  )
}
