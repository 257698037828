import { useField } from 'formik';
import React from 'react';

export default function SelectField(props) {
  const { label, options, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.error;

  const labelClassName = props.labelClassName;

  return (
    <label className={`block w-full text-darkGreen  ${props.className || ''}`}>
      <div className="mb-1">

        <div className={`${labelClassName}  t-16 `}>{label}</div>
      </div>
      <div className="relative w-full">
        <select
          className={`h-[45px] w-full px-4 py-3 rounded-[10px] flex-1 leading-4 bg-white border-[rgba(7,27,30,0.30)] appearance-none border transition-all duration-200 ${isInvalid ? '!border-red ':'focus:!border-midGreen'}`}
          style={{
            backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 1.27313L6.99995 8L0 1.27312L1.32484 4.78452e-06L6.99996 5.45374L12.6752 0L14 1.27313Z" fill="%23062A2F"/></svg>')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 20px center',
          }}
          {...field}
          {...other}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value}>{option.label}</option>
          ))}
          </select>
      </div>
      {isInvalid && <div className="text-red text-[14px] pt-1">{meta.error}</div>}
    </label>
  );
}
